import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createI18n } from 'vue-i18n';
import WebsiteLayout from './Layouts/Website.vue';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

// Set up vue-i18n instance
const i18n = createI18n({
	legacy: false,
	globalInjection: true, // habilita el uso de $t globalmente en los .vue
	locale: 'en',
	fallbackLocale: 'es',
	messages: {},
});

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: async (name) => {
		const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));
		page.default.layout = page.default.layout || WebsiteLayout;
		return page;
	},
	setup({ el, App, props, plugin }) {
		console.debug(props);

		i18n.global.setLocaleMessage(props.locale, props.translations);

		console.debug(props.translations);

		return createApp({ render: () => h(App, props) })
			.use(plugin)
			.use(ZiggyVue)
			.use(i18n)
			.mount(el);
	},
	progress: {
		color: '#4B5563',
	},
});

