<script setup>
import { computed } from 'vue';
import IconArrowUpRight from '@/images/icons/arrow-up-right.svg';

const props = defineProps({
	href: {
		type: String,
		required: false,
	},
	filled: {
		type: Boolean,
		required: false,
	},
	variant: {
		type: String,
		required: false,
	},
	withArrow: {
		type: Boolean,
		required: false,
	},
});

const classes = computed(() => {
	let c = (props.filled ? 'filled' : 'outlined') + ' ';
	if (['light', 'dark', 'special'].includes(props.variant)) {
		c += props.variant;
	}
	return c;
});

const tag = props.href ? 'a' : 'button';
</script>

<template>
	<component :is="tag" :href="href" class="button" :class="classes">
		<span>
			<slot />
		</span>
		<img v-if="withArrow" :src="IconArrowUpRight" class="icon" alt="">
	</component>
</template>

<style lang="postcss" scoped>
@property --gradient-angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}

a,
button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 6px 30px;
	font-weight: 500;
	color: inherit;
	color: var(--text-color);
	border: 1px solid transparent;
	border-radius: 24em;
	cursor: pointer;
	transition: all 0.2s ease;
	z-index: 5;

	&.light {
		color: var(--color-text-dark);
		border-color: #474646;

		.icon {
			filter: invert(1);
		}

		&:hover {
			.icon {
				filter: invert(0);
			}
		}
	}

	&.dark {
		color: var(--color-text-light);
		border-color: #EDEDED
	}

	&.filled,
	&:hover {
		color: var(--color-text-light);
		background-color: var(--color-blue);
		border-color: var(--color-blue);
	}

	.icon {
		margin-left: 10px;
		margin-right: -2px;
		width: 16px;
		max-height: 16px;
		transition: inherit;
	}

	/* Botón con border gradient */
	&.special {
		position: relative;
		display: inline-block;
		padding: 10px 20px;
		font-size: 16px;
		line-height: 1;
		background-color: transparent;
		border: none;
		border-radius: 6px;
		cursor: pointer;
		overflow: hidden;
	}

	&.special span {
		position: relative;
		z-index: 1;
	}

	&.special::before,
	&.special::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 0.2s ease;
		z-index: -1;
	}

	&.special::before {
		background: linear-gradient(var(--gradient-angle), var(--color-blue) 0%, #484547 60%, #484547 100%);
		border-radius: inherit;
		padding: 2px; /* sería su border width */
		mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
		mask-composite: exclude;
		-webkit-mask-composite: xor;
		animation: rotate-gradient 4s linear infinite;
	}

	&.special::after {
		background-color: var(--color-blue);
		transition: all 0.4s ease;
		opacity: 0;
	}

	&.special:hover::after {
		opacity: 1;
	}

	&.special:hover {
		color: var(--color-text-light);
	}

	&.special:focus {
		outline: none;
		box-shadow: 0 0 0 3px rgba(64, 128, 255, 0&.5);
	}

	&.special:active {
		transform: translateY(1px);
	}

	&.special span::before {
		content: '';
		position: absolute;
		top: 40%;
		left: 50%;
		width: 70%;
		height: 30%;
		transform: translate(-50%, -50%);
		background-color: var(--color-blue);
		border-radius: 50%;
		filter: blur(10px);
		opacity: 0.8;
	}

}

@keyframes rotate-gradient {
	0% {
		--gradient-angle: 0deg;
	}

	100% {
		--gradient-angle: 360deg;
	}
}
</style>
