<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { Link } from '@inertiajs/vue3';

import Button from '@/js/Components/Button.vue';
import LogoDott from '@/js/Components/LogoDott.vue';

const header = ref(null);
const menuOpen = ref(false);
const isScrolled = ref(false);

const handleScroll = () => isScrolled.value = window.scrollY > 40; // TODO -> gradual?
const toggleMenuMobile = () => menuOpen.value = !menuOpen.value;

const closeMenu = (event) => {
	if (menuOpen.value && header.value && (!header.value.contains(event.target) || event.target.closest('a'))) {
		menuOpen.value = false;
	}
};

onMounted(() => {
	window.addEventListener('scroll', handleScroll);
	window.addEventListener('click', closeMenu);
});

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll);
	window.removeEventListener('click', closeMenu);
});
</script>

<template>
	<header ref="header" :class="{ 'scrolled': isScrolled, 'open': menuOpen }"
		class="top-0 z-50 flex flex-col items-center justify-center w-full">
		<nav class="container narrow">
			<Link href="/">
				<LogoDott />
			</Link>
			<div class="links">
				<Link href="#services">Servicios</Link>
				<Link href="#methodology">Metodología</Link>
				<Link href="#about">Acerca de</Link>
				<Button class="mobile" href="#contact" variant="special">Contacto</Button>
			</div>
			<Button class="desktop" href="#contact" variant="special">Contacto</Button>
			<span class="button hamburger mobile" @click="toggleMenuMobile">
				<i></i>
			</span>
		</nav>
		<div class="bg">
			<div></div>
			<div></div>
		</div>
	</header>
</template>

<style lang="postcss">
header {
	position: sticky;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	min-height: var(--nav-height);

	.logo-dott img {
		max-width: 64px;
		transition: filter 0.2s ease;
	}

	nav {
		--padding-x: 30px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&.narrow {
			max-width: calc(var(--width-narrow) + var(--padding-x) * 2);
		}

		padding: 10px var(--padding-x);
		border-radius: 16px;
		box-shadow: 0 4px 20px rgba(0, 0, 0, 0);
		border: 2px solid rgba(255, 255, 255, 0);

		.links {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			column-gap: 20px;

			a {
				padding: 2px 10px;
				font-weight: 400;
				text-shadow: 0 1px 2px rgba(255, 255, 255, .2);
			}
		}

		.button.hamburger {
			--width: 44px;
			--height: 44px;
			position: absolute;
			display: block;
			top: 10px;
			right: 3vw;
			background-color: transparent;
			border-radius: 5px;
			color: inherit;
			width: var(--width);
			height: var(--height);

			i {
				--line-width: 2px;
				--line-color: white;
				position: absolute;
				display: block;
				top: 50%;
				left: calc(50% - calc(var(--width) * 0.25));
				width: 100%;
				max-width: 22px;
				height: var(--line-width);
				background-color: var(--line-color);
				border-radius: var(--line-width);
				transition: all .4s ease;

				&:before,
				&:after {
					content: '';
					position: absolute;
					display: block;
					left: 50%;
					width: 80%;
					height: inherit;
					background-color: var(--line-color);
					border-radius: inherit;
					transform: translate3d(-50%, 0, 0);
					transform-origin: center;
					transition: inherit;
				}

				&:before {
					transition-duration: 0.2s;
					top: calc(var(--line-width) * -2.5);
				}

				&:after {
					transition-duration: .4s;
					bottom: calc(var(--line-width) * -2.5);
				}
			}
		}

		&::after {
			content: '';
			position: absolute;
			display: block;
			top: -2px;
			left: -2px;
			right: -2px;
			bottom: -2px;
			background-color: rgba(255, 255, 255, 0.6);
			border-radius: inherit;
			backdrop-filter: blur(5px);
			z-index: -1;
			filter: blur(5px);
			opacity: 0;
		}
	}

	&.open,
	&.scrolled {
		nav {
			backdrop-filter: blur(10px);
			background-color: rgba(255, 255, 255, 0.2);
			box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
			border-color: rgba(255, 255, 255, 0.3);

			a {
				--text-color: var(--color-text-dark);
			}

			&::after {
				opacity: 1;
			}

			.logo-dott img {
				filter: invert(1);
			}

			.button.hamburger i {
				--line-color: var(--color-text-dark);
			}
		}
	}

	.bg {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100%;
		opacity: 1;
		z-index: -1;
		overflow: hidden;
	}

	.bg::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: inherit;
		mask-image: linear-gradient(to bottom,
		transparent,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0.6) 20%,
			rgba(0, 0, 0, 0.4) 40%,
			rgba(0, 0, 0, 0.2) 60%,
			rgba(0, 0, 0, 0) 100%
		);
		backdrop-filter: brightness(0.6) grayscale(1);
	}
}

@media screen and (max-width: 768px) {
	:root {
		--nav-height: 80px;
	}

	header {
		position: fixed;
		padding-top: 0;

		.logo-dott img {
			max-width: 52px;
		}

		&.open {
			.links {
				display: flex;
			}

			.button.hamburger {
				i {
					--line-color: var(--color-text-dark);
					background-color: transparent;
					transform: rotate(135deg);

					&:before {
						top: 0;
						transform: translate3d(-50%, 0, 0) rotate(-90deg);
					}

					&:after {
						bottom: 0;
						transform: translate3d(-50%, 0, 0) rotate(0deg);
					}
				}
			}
		}

		nav {
			box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
			flex-direction: column;
			gap: 30px;
			border-radius: 0;

			&.container {
				padding-top: 24px;
				padding-bottom: 20px;
				width: 100%;
			}

			.links {
				display: none;
				flex-direction: column;
				gap: 10px;
				padding: 0 20px;

				a:not(.button) {
					padding: 10px 20px;
					font-size: 22px;
					font-weight: 300;
					color: var(--color-blue);
				}

				a.button {
					margin-top: 20px;
					margin-bottom: 20px;
				}
			}
		}
	}
}
</style>

